import React, { useState, useEffect, useContext } from "react";
import { Buffer } from "buffer";

import "./Profile.css";

import Config from "./Config";
import AppContext from "./contexts/AppContext";

function Profile() {
    const [selectedIdentitiesItem, setSelectedIdentitiesItem] = useState(null);
    const [selectedProfilesItem, setSelectedProfilesItem] = useState(null);
    const [selectedRevisionsItem, setSelectedRevisionsItem] = useState(null);
    const [selectedResourcesItem, setSelectedResourcesItem] = useState(null);
    const [selectedClassId, setSelectedClassId] = useState(null);
    const [selectedCode, setSelectedCode] = useState(null);

    const {
        updated,
        identities,
        deleteIdentity,
        profiles,
        revisions,
        resources,
        updateRevisionClassSource,
        deleteRevisionClassSource,
        createRevision,
        deleteRevision,
        createProfile,
        deleteProfile,
    } = useContext(AppContext);

    useEffect(() => {
        if (identities.length === 1) setSelectedIdentitiesItem(identities[0].record_id);
    }, [identities]);
    useEffect(() => {
        if (profiles.length === 1 && identities.length === 1) setSelectedProfilesItem(profiles[0].record_id);
    }, [profiles, identities]);
    useEffect(() => {
        if (revisions.length === 1 && profiles.length === 1 && identities.length === 1) setSelectedRevisionsItem(revisions[0].record_id);
    }, [revisions, profiles, identities]);

    async function onClickSaveResource() {
        const resourceBuffer = selectedCode ? Buffer.from(selectedCode) : null;
        if (resourceBuffer === null) return;
        await updateRevisionClassSource(selectedProfilesItem, selectedRevisionsItem, selectedClassId, resourceBuffer);
    }

    async function onClickNewResource() {
        const resourceBuffer = selectedCode ? Buffer.from(selectedCode) : "pass";
        await updateRevisionClassSource(selectedProfilesItem, selectedRevisionsItem, selectedClassId ? selectedClassId : Config.application.UnitSquareClassUUID, resourceBuffer);
    }

    async function onClickDeleteResource() {
        await deleteRevisionClassSource(selectedProfilesItem, selectedRevisionsItem, selectedClassId);
    }

    async function onClickNewRevision() {
        await createRevision(selectedProfilesItem);
    }

    async function onClickDeleteRevision() {
        await deleteRevision(selectedProfilesItem, selectedRevisionsItem);
    }

    async function onClickNewProfile() {
        await createProfile();
    }

    async function onClickDeleteProfile() {
        await deleteProfile(selectedProfilesItem);
    }

    async function onClickDeleteIdentity() {
        await deleteIdentity(selectedIdentitiesItem);
    }

    // useEffect(() => {
    //     // update textarea
    //     if (selectedResourcesItem) {
    //         const resource = resources[selectedResourcesItem];
    //         document.getElementById("Resource").getElementsByTagName("textarea")[0].value = JSON.stringify(resource, null, 2);
    //     }
    // }, [selectedResourcesItem, resources]);

    useEffect(() => {
        if (selectedCode) {
            document.getElementById("resource").getElementsByTagName("textarea")[0].value = Buffer.from(selectedCode, "base64").toString();
        }
    }, [resources]);


    if ( identities.length === 0 ) {
        return (
            <h1>Loading...</h1>
        )
    }

    return (
        <>
            <div id="identity">
            Identity:
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Identity ID</th>
                            <th>Description</th>
                            <th>Profiles</th>
                        </tr>
                    </thead>
                    <tbody>
                        {identities.map((lobby, index) => {
                            return (
                                <tr key={lobby.record_id} onClick={() => setSelectedIdentitiesItem(lobby.record_id)}>
                                    <td>{index}</td>
                                    <td>{lobby.record_id}</td>
                                    <td>{lobby.description}</td>
                                    <td>{lobby.mapping_profiles.join(',')}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <div id="profile">
                Profile:
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Profile ID</th>
                            <th>Description</th>
                            <th>Revisions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedIdentitiesItem &&
                        profiles.map((profile, index) => {
                            return (
                                <tr key={profile.record_id} onClick={() => {
                                        setSelectedProfilesItem(profile.record_id);
                                        setSelectedRevisionsItem(null);
                                        setSelectedResourcesItem(null);
                                    }}>
                                    <td>{index}</td>
                                    <td>{profile.record_id}</td>
                                    <td>{profile.description}</td>
                                    <td>{profile.mapping_revisions.join(',')}</td>
                                    <td>
                                        <button onClick={onClickDeleteProfile}> Delete </button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <button onClick={onClickNewProfile}> New </button>
            </div>

            <div id="revision">
                Revision:
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Revision ID</th>
                            <th>Description</th>
                            <th>Mappings</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedProfilesItem &&
                        revisions.filter((revision) => revision.profile_id === selectedProfilesItem).map((revision, index) => {
                            return (
                                <tr key={revision.record_id}
                                    onClick={() => {
                                        setSelectedRevisionsItem(revision.record_id)
                                        setSelectedResourcesItem(null);
                                        }
                                    }
                                >
                                    <td>{index}</td>
                                    <td>{revision.record_id}</td>
                                    <td>{revision.description}</td>
                                    <td>{Object.entries(revision.class_mappings).map(([key, value]) => `${
                                        Object.entries(Config.application).filter(([name, v]) => v === key)?.[0]?.[0] || key
                                    }`).join(',')}</td>
                                    <td>
                                        <button onClick={onClickDeleteRevision}> Delete </button>
                                    </td>
                                </tr>
                            )}
                        )}

                    </tbody>
                </table>
                <button onClick={onClickNewRevision} disabled={!selectedProfilesItem}> New </button>
            </div>

            <div id="class">
                Class:
                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Class ID</th>
                            <th>Description</th>
                            <th>Resource</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedRevisionsItem &&
                        revisions.filter((revision) => revision.record_id === selectedRevisionsItem).map((revision) => (
                            Object.entries(revision.class_mappings).map(([class_id, source_id], index) => (
                                <tr key={class_id} onClick={() => {
                                        setSelectedResourcesItem(source_id);
                                        setSelectedClassId(class_id)
                                        setSelectedCode(Buffer.from(resources[source_id], "base64").toString());
                                    }}>
                                    <td>{index}</td>
                                    <td>{class_id}</td>
                                    <td>{Object.entries(Config.application).filter(([name, v]) => v === class_id)?.[0]?.[0] || class_id}</td>
                                    <td>{source_id}</td>
                                    <td>
                                        <button onClick={onClickDeleteResource}> Delete </button>
                                    </td>
                                </tr>
                            ))
                        ))}
                    </tbody>
                </table>
                <button onClick={onClickNewResource} disabled={!selectedRevisionsItem}> New </button>
            </div>

            <div id="resource">
                Resource: <br />

                {selectedResourcesItem &&
                <textarea
                    value={selectedCode}
                    onChange={(e) => {
                        setSelectedCode(e.target.value);
                    }}
                ></textarea>
                }
                <br />

                <button onClick={onClickSaveResource} disabled={!selectedCode}> Save </button>
            </div>
        </>
    );
}

export default Profile;