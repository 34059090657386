// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#scores table thead th, td {
    border: 1px solid;
    padding: 8px;
    /* background-color: #f1f1c1; */
    border-collapse: collapse;
}

#scores table tbody tr:hover {
    /* background-color: #f1f1c1; */
    background: none repeat scroll 0 0 #FFCF8B;
    color: #000000;
}

#scores table tbody tr.selected td {
    background-color: none repeat scroll 0 0 #FFCF8B;
}
`, "",{"version":3,"sources":["webpack://./src/Scores.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,+BAA+B;IAC/B,yBAAyB;AAC7B;;AAEA;IACI,+BAA+B;IAC/B,0CAA0C;IAC1C,cAAc;AAClB;;AAEA;IACI,gDAAgD;AACpD","sourcesContent":["#scores table thead th, td {\n    border: 1px solid;\n    padding: 8px;\n    /* background-color: #f1f1c1; */\n    border-collapse: collapse;\n}\n\n#scores table tbody tr:hover {\n    /* background-color: #f1f1c1; */\n    background: none repeat scroll 0 0 #FFCF8B;\n    color: #000000;\n}\n\n#scores table tbody tr.selected td {\n    background-color: none repeat scroll 0 0 #FFCF8B;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
