import React, { useState, useEffect, useContext, useTransition} from 'react';
import { useQuery } from 'react-query';

import {
    createFetcher,
    deleteIdentity as apiDeleteIdentity,
    getIdentities,
    getProfiles,
    getRevisions,
    getLobbies,
    postJoinLobby,
    postLeaveLobby,
    postCreateLobby,
    deleteLobby as apiDeleteLobby,
    postUpdateLobby,
    getScores as apiGetScores,
    postScore as apiPostScore,
    getResults as apiGetResults,
    getResultData as apiGetResultData,
    postNewRevisionClassSource as apiPostNewRevisionClassSource,
    deleteRevisionClassSource as apiDeleteRevisionClassSource,
    deleteRevision as apiDeleteRevision,
    postNewRevision,
    deleteProfile as apiDeleteProfile,
    postNewProfile,
} from "../utils";


import AppContext from './AppContext';
import AuthContext from './AuthContext';


const AppContextProvider = ({ children }) => {
    const [identities, setIdentities] = useState([]);
    const [profiles, setProfiles] = useState([]);
    const [revisions, setRevisions] = useState([]);
    const [resources, setResources] = useState([]);
    const [lobbies, setLobbies] = useState([]);
    const [games, setGames] = useState([]);
    const [scores, setScores] = useState([]);
    const [results, setResults] = useState([]);
    const [refresh, setRefresh] = useState(performance.now());
    const [isPending, startTransition] = useTransition();

    const { authenticated, token } = useContext(AuthContext);
    // const controller = new AbortController();
    // const id = setTimeout(() => controller.abort(), 10000)

    useEffect(() => {
        if ( !authenticated) return;

        startTransition(() => {
            const {fetcher, canceller} = createFetcher(token);

            getIdentities(fetcher)
            .then(({identities}) => {
                setIdentities(identities);
                return getProfiles(fetcher, { identities })
            })
            .then(({identities, profiles}) => {
                setProfiles(profiles);
                return getRevisions(fetcher, { profiles })
            })
            .then((data) => {
                setRevisions(data.revisions);
                setResources(data.resources);
            })
            .catch((error) => {
                // console.error(error);
            });

            return canceller;
        });

    }, [token, authenticated, refresh]);

    // Lobbies
    useEffect(() => {
        if ( !authenticated ) return;

        const {fetcher, canceller} = createFetcher(token);

        getLobbies(fetcher).then((data) => {
            setLobbies(data.lobbies);
        })
        .catch((error) => {
            // console.error(error);
        });
        return canceller
    }, [token, authenticated, refresh]);

    // Scores
    useEffect(() => {
        if ( !authenticated ) return;

        const {fetcher, canceller} = createFetcher(token);
        apiGetScores(fetcher).then((data) => {
            setScores(data.scores);
        })
        .catch((error) => {
            // console.error(error);
        });
        return canceller
    }, [token, authenticated, refresh]);

    // Results
    useEffect(() => {
        if ( !authenticated ) return;

        const {fetcher, canceller} = createFetcher(token);
        apiGetResults(fetcher).then((data) => {
            setResults(data.results);
        })
        .catch((error) => {
            // console.error(error);
        });

        return canceller
    }, [token, authenticated, refresh]);

    const deleteIdentity = async (identityId) => {
        const {fetcher, canceller} = createFetcher(token);
        return await apiDeleteIdentity(fetcher, identityId).then((data) => {
            setUpdatedNow();
        })
    }


    const createRevision = async (profileId) => {
        const {fetcher, canceller} = createFetcher(token);
        return await postNewRevision(fetcher, profileId).then((data) => {
            // setRevisions(data.revisions);
            setUpdatedNow();
        })
    }

    const deleteRevision = async (profileId, revisionId) => {
        const {fetcher, canceller} = createFetcher(token);
        return await apiDeleteRevision(fetcher, profileId, revisionId).then((data) => {
            // setRevisions(data.revisions);
            setUpdatedNow();
        })
    }

    const createProfile = async () => {
        const {fetcher, canceller} = createFetcher(token);
        return await postNewProfile(fetcher).then((data) => {
            // setProfiles(data.profiles);
            setUpdatedNow();
        })
    }

    const deleteProfile = async (profileId) => {
        const {fetcher, canceller} = createFetcher(token);
        return await apiDeleteProfile(fetcher, profileId).then((data) => {
            // setProfiles(data.profiles);
            setUpdatedNow();
        })
    }

    const getResultData = async (resultId) => {
        const {fetcher, canceller} = createFetcher(token);
        return await apiGetResultData(fetcher, resultId, { results }).then((data) => {
            setResults(data.results);
            return data.game_report;
        })
    }

    const joinLobby = async (lobbyId, slot_key = null, slot_revision_id = null) => {
        const {fetcher, canceller} = createFetcher(token);
        await postJoinLobby(fetcher, lobbyId, slot_key, slot_revision_id, { lobbies }).then((data) => {
            setUpdatedNow();
        })
    }

    const leaveLobby = async (lobbyId, slot_key = null) => {
        const {fetcher, canceller} = createFetcher(token);
        await postLeaveLobby(fetcher, lobbyId, slot_key).then((data) => {
            setUpdatedNow();
        })
    }

    const createLobby = async (slot_revision_id, slotCount = 2, slot_key = 0) => {
        const {fetcher, canceller} = createFetcher(token);
        const slots = new Array(slotCount).keys().reduce((acc, index) => {
            acc[index] = index === slot_key ? slot_revision_id : null;
            return acc;
        }, {});
        await postCreateLobby(fetcher, slots).then((data) => {
            setUpdatedNow();
        })
    }
    const deleteLobby = async (lobbyId) => {
        const {fetcher, canceller} = createFetcher(token);
        await apiDeleteLobby(fetcher, lobbyId).then((data) => {
            setUpdatedNow();
        })
    }

    const updateLobby = async (lobbyId, ready, add_slots_keys, update_slots, remove_slots_keys, owner_profile_id, remove_owner_profile_id, lobby_configuration, is_public) => {
        const {fetcher, canceller} = createFetcher(token);
        const options = {
            ready: ready,
            // add_slots_keys: [],
            // update_slots: {},
            // remove_slots_keys: [],
            // owner_profile_id: "",
            // remove_owner_profile_id: false,
            // lobby_configuration: {},
            // is_public: true
         };
        await postUpdateLobby(fetcher, lobbyId, options).then((data) => {
            setUpdatedNow();
        })
    }

    const updateRevisionClassSource = async (profileId, revisionId, classId, classData) => {
        const {fetcher, canceller} = createFetcher(token);
        await apiPostNewRevisionClassSource(fetcher, profileId, revisionId, classId, classData).then((data) => {
            setUpdatedNow();
        })
    }

    const deleteRevisionClassSource = async (profileId, revisionId, classId) => {
        const {fetcher, canceller} = createFetcher(token);
        await apiDeleteRevisionClassSource(fetcher, profileId, revisionId, classId).then((data) => {
            setUpdatedNow();
        })
    }

    const createScore = async (score_scope_type, scope_reference_id) => {
        const {fetcher, canceller} = createFetcher(token);
        await apiPostScore(fetcher, score_scope_type, scope_reference_id).then((data) => {
            setUpdatedNow();
        })
    }

    const setUpdatedNow = () => {
        setRefresh(performance.now());
    }

    return (
        <AppContext.Provider value={{
            identities: identities,
            setIdentities: setIdentities,
            deleteIdentity: deleteIdentity,
            profiles: profiles,
            setProfiles: setProfiles,
            revisions: revisions,
            setRevisions: setRevisions,
            resources: resources,
            setResources: setResources,
            lobbies: lobbies,
            joinLobby: joinLobby,
            leaveLobby: leaveLobby,
            createLobby: createLobby,
            deleteLobby: deleteLobby,
            updateLobby: updateLobby,
            games: games,
            setGames: setGames,
            setUpdated: setUpdatedNow,
            scores: scores,
            getScores: () => {},
            createScore: createScore,
            results: results,
            getResults: () => {},
            getResultData: getResultData,
            updateRevisionClassSource: updateRevisionClassSource,
            deleteRevisionClassSource: deleteRevisionClassSource,
            createRevision: createRevision,
            deleteRevision: deleteRevision,
            createProfile: createProfile,
            deleteProfile: deleteProfile,
        }}>
        {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;