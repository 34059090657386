import React, { useState, useEffect} from 'react';
import { useLocation } from "react-router-dom";

import { useLocalStorage } from '../utils';

import { useCookies, withCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import {
	CognitoUserPool,
	CognitoUserAttribute,
	CognitoUser,
} from 'amazon-cognito-identity-js';


import AuthContext from './AuthContext';

const ApiContextProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useLocalStorage('codegames-authenticated', false);
    const [token, setToken] = useLocalStorage('codegames-token', null);
    // const [expiresAfter, setExpiresAfter] = useLocalStorage('codegames-expiresAfter', null);
    const { hash } = useLocation();

    useEffect(() => {
        const hashParamMap = hash.slice(1).split('&').reduce((acc, item) => {
            const [key, value] = item.split('=');
            acc[key] = value;
            return acc;
        }, {});

        var idToken = hashParamMap['id_token'];
        // var expiresIn = hashParamMap['expires_in'];

        if (idToken === undefined && token) {
            idToken = token;
        }

        if (idToken) {
            if (idToken) {
                setToken(idToken);
            }
            fetch("/api/user/status", {
                headers: {
                    Authorization: `Bearer ${idToken}`
                },
            })
            .then((res) => {
                if (res.status === 200) {
                    setAuthenticated(true);
                } else {
                    // setToken(null);
                    setAuthenticated(false);
                }
            });
        }
    }, [hash, authenticated, token]);

    const logout = () => {
        setToken(null);
        setAuthenticated(false);
    }

    return (
        <AuthContext.Provider value={{
            authenticated,
            token,
            Logout: logout,
            }}>
        {children}
        </AuthContext.Provider>
    );
};

export default ApiContextProvider;