// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#lobbies table thead th, td:not(:last-child) {
    border: 1px solid;
    padding: 8px;
    /* background-color: #f1f1c1; */
    border-collapse: collapse;
}

#lobbies table tbody tr:hover {
    background-color: #f1f1c1;
}

/* set slotCount default value 2*/
#lobbies-create input[name="slotCount"] {
    width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Lobbies.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,+BAA+B;IAC/B,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA,iCAAiC;AACjC;IACI,WAAW;AACf","sourcesContent":["#lobbies table thead th, td:not(:last-child) {\n    border: 1px solid;\n    padding: 8px;\n    /* background-color: #f1f1c1; */\n    border-collapse: collapse;\n}\n\n#lobbies table tbody tr:hover {\n    background-color: #f1f1c1;\n}\n\n/* set slotCount default value 2*/\n#lobbies-create input[name=\"slotCount\"] {\n    width: 50px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
