import React, { useContext } from 'react';
import './Navbar.css';

import { useNavigate } from 'react-router-dom';

import AuthContext from './contexts/AuthContext';


const Navbar = () => {
    const navigate = useNavigate();
    const { authenticated, token } = useContext(AuthContext);

    const handleClick = (e) => {
        e.preventDefault();
        navigate(e.target.getAttribute('href'), { replace: true });
        return false;
    }
    return (
        <nav className="navbar">
            <a href="/profile" onClick={handleClick}>Profile</a>
            <a href="/lobbies" onClick={handleClick}>Lobbies</a>
            <a href="/scores" onClick={handleClick}>Scores</a>
            <a href="/results" onClick={handleClick}>Results</a>
            <a href="/about" onClick={handleClick}>About</a>
            { authenticated ? <a href="/logout" onClick={handleClick}>Logout</a> : <a href="/login" onClick={handleClick}>Login</a> }
        </nav>
    );
};

export default Navbar;