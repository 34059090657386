// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table tbody tr.selected td {
  background: none repeat scroll 0 0 #FFCF8B;
  color: #000000;
}

#identity table thead th, #identity table thead td:not(:last-child) {
  border: 1px solid;
  padding: 8px;
  /* background-color: #f1f1c1; */
  border-collapse: collapse;
}

#identity table tbody tr:hover {
  background-color: #f1f1c1;
}

#profile table thead th, #profile table thead td:not(:last-child) {
  border: 1px solid;
  padding: 8px;
  /* background-color: #f1f1c1; */
  border-collapse: collapse;
}

#profile table tbody tr:hover {
  background-color: #f1f1c1;
}

#revision table thead th, #revision table thead td:not(:last-child) {
  border: 1px solid;
  padding: 8px;
  /* background-color: #f1f1c1; */
  border-collapse: collapse;
}

#revision table tbody tr:hover {
  background-color: #f1f1c1;
}

#class table thead th, #class table thead td:not(:last-child) {
  border: 1px solid;
  padding: 8px;
  /* background-color: #f1f1c1; */
  border-collapse: collapse;
}

#class table tbody tr:hover {
  background-color: #f1f1c1;
}

#resource textarea {
  width: 80%;
  height: 100px;
}

`, "",{"version":3,"sources":["webpack://./src/Profile.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,+BAA+B;EAC/B,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,+BAA+B;EAC/B,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,+BAA+B;EAC/B,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,+BAA+B;EAC/B,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,UAAU;EACV,aAAa;AACf","sourcesContent":["table tbody tr.selected td {\n  background: none repeat scroll 0 0 #FFCF8B;\n  color: #000000;\n}\n\n#identity table thead th, #identity table thead td:not(:last-child) {\n  border: 1px solid;\n  padding: 8px;\n  /* background-color: #f1f1c1; */\n  border-collapse: collapse;\n}\n\n#identity table tbody tr:hover {\n  background-color: #f1f1c1;\n}\n\n#profile table thead th, #profile table thead td:not(:last-child) {\n  border: 1px solid;\n  padding: 8px;\n  /* background-color: #f1f1c1; */\n  border-collapse: collapse;\n}\n\n#profile table tbody tr:hover {\n  background-color: #f1f1c1;\n}\n\n#revision table thead th, #revision table thead td:not(:last-child) {\n  border: 1px solid;\n  padding: 8px;\n  /* background-color: #f1f1c1; */\n  border-collapse: collapse;\n}\n\n#revision table tbody tr:hover {\n  background-color: #f1f1c1;\n}\n\n#class table thead th, #class table thead td:not(:last-child) {\n  border: 1px solid;\n  padding: 8px;\n  /* background-color: #f1f1c1; */\n  border-collapse: collapse;\n}\n\n#class table tbody tr:hover {\n  background-color: #f1f1c1;\n}\n\n#resource textarea {\n  width: 80%;\n  height: 100px;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
