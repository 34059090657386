import React, { useState, useEffect, useContext } from "react";

import "./Lobbies.css";
import Config from "./Config";
import AppContext from "./contexts/AppContext";
import { debounce } from "./utils";

function Lobbies() {
    // const [selectedIdentitiesItem, setSelectedIdentitiesItem] = useState(null);
    // const [selectedProfilesItem, setSelectedProfilesItem] = useState(null);
    // const [selectedRevisionsItem, setSelectedRevisionsItem] = useState(null);

    const {
        identities,
        profiles,
        revisions,
        lobbies,
        joinLobby,
        setUpdated,
        leaveLobby,
        createLobby,
        deleteLobby,
        updateLobby,
    } = useContext(AppContext);

    const onJoinLobby = (lobbyId) => {
        joinLobby(lobbyId);
    }

    const onRefresh = () => {
        setUpdated();
    }
    const debouncedOnRefresh = debounce(onRefresh, 1000);

    const onLeaveLobby = (lobbyId) => {
        leaveLobby(lobbyId);
    }

    const onCreateLobby = (slot_revision_id, slotCount) => {
        createLobby(slot_revision_id, slotCount, 0);
    }

    const onDeleteLobby = (lobbyId) => {
        deleteLobby(lobbyId);
    }

    const onReady = (lobbyId, ready) => {
        updateLobby(lobbyId, ready);
    }

    if ( identities.length === 0 ) {
        return (
            <h1>Loading...</h1>
        )
    }

    return (
        <>
            <div id="lobbies">
                <h2>Lobbies:</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Lobby ID</th>
                            <th>Owner</th>
                            <th>Status</th>
                            <th>Slots</th>
                            <th>Configuration</th>
                            <th>Revision</th>
                        </tr>
                    </thead>
                    <tbody>
                        {lobbies.map((lobby) => {
                            const isMember = Object.values(lobby.slots).filter(value => revisions.find((revision) => revision.record_id === value)).length > 0;
                            const isJoinable = Object.values(lobby.slots).filter(value => value !== null).length < Object.keys(lobby.slots).length;
                            const isOwner = profiles.find((profile) => profile.record_id === lobby.owner_profile_id);
                            const isStarted = lobby.game_record_ids.length > 0;
                            const item = (<tr key={lobby.record_id}>
                                <td>{lobby.record_id}</td>
                                <td>{lobby.is_public ? (isOwner ? ("public / " +lobby.owner_profile_id) : "public") : "private / " + lobby.owner_profile_id}</td>
                                <td>{isStarted ? ("started /[ game:" + lobby.game_record_ids + " ]" ): lobby.ready ? "locked" : "open" }</td>
                                <td>{Object.values(lobby.slots).filter(value => value !== null).length + "/" + Object.keys(lobby.slots).length}</td>
                                <td>{JSON.stringify(lobby.lobby_configuration)}</td>
                                <td>{Object.entries(lobby.slots).reduce((acc, [key, value]) => {
                                    if (revisions.find((revision) => revision.record_id === value)) {
                                        acc.push(key + ":" + value);
                                    }
                                    return acc;
                                }, []).join(',')}</td>
                                <td>
                                    <button disabled={isStarted || !isJoinable || isMember} onClick={() => onJoinLobby(lobby.record_id)}>Join</button>
                                    <button disabled={ isStarted || !isMember} onClick={() => onLeaveLobby(lobby.record_id)}>Leave</button>
                                    <button disabled={isStarted || !isOwner } onClick={() => onReady(lobby.record_id, !lobby.ready)}>{lobby.ready ? "Unlock" : "Lock"}</button>
                                    <button disabled={isStarted || !isOwner } onClick={() => onDeleteLobby(lobby.record_id)}>Delete</button>
                                </td>
                            </tr>);
                            return item;
                        })}
                    </tbody>
                </table>
            </div>
            <button onClick={() => {debouncedOnRefresh()}}>Refresh</button>
            {/* <button onClick={() => {onCreateLobby()}}>Create 1v1</button> */}

            <div id="lobbies-create">
                <h2>Create Lobby</h2>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    const formData = new FormData(e.target);
                    const slotCount = formData.get("slotCount");
                    const revisionId = formData.get("revisionId");
                    onCreateLobby(revisionId, slotCount);
                }}>
                    <label>
                        Slot Count:
                        <input type="number" defaultValue="2" name="slotCount" />
                    </label>
                    <label>
                        Revision ID:
                        <select name="revisionId">
                            {revisions.map((revision) => {
                                return (
                                    <option key={revision.record_id} value={revision.record_id}>{revision.record_id}</option>
                                );
                            })}
                        </select>
                    </label>
                    <button type="submit">Create Lobby</button>
                </form>
            </div>
            <div>
                <p>
                    <small>
                        <i>
                            <b>1:</b> When you create a lobby, you are the owner and the only one who can start the game.
                            <br />
                            <b>2:</b> When you lock a lobby no one can join or leave the lobby.
                            <br />
                            <b>3:</b> Shortly after a lobby is locked it will be started. The allocated game ID will be displayed in the lobby.
                            <br />
                            <b>4:</b> Once a game is played a Result record should be created.
                            <br />
                            <b>5:</b> If a Score score record exists the result will update score records of the player Identities, Profiles, and Revisions.
                        </i>
                    </small>
                </p>
            </div>
        </>
    );
}

export default Lobbies;