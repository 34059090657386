import React, { useContext, useEffect, useState } from 'react';

import AuthContext from './contexts/AuthContext';
import Config from './Config';

function Logout() {
  const callbackUrl = Config.callbackUrl;
  const clientId = Config.clientId;
  const scope = Config.scope;
  const responseType = Config.responseType;
  const authUrl = Config.authUrl;
  const targetUrl = `${authUrl}?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${encodeURIComponent(
  callbackUrl
  )}&state=${window.location.pathname}`;

  const { authenticated: authenticated, token: token, Logout:logoutFn} = useContext(AuthContext);

  useEffect(() => {
    logoutFn();
    window.location.href = targetUrl;
  });

}

export default Logout;
