import React, { useState, useEffect, useContext } from "react";

import "./Results.css";
import Config from "./Config";
import AppContext from "./contexts/AppContext";
import { debounce } from "./utils";

function Results() {

    const {
        identities,
        profiles,
        revisions,
        lobbies,
        scores,
        results,
        setUpdated,
        getResultData,
    } = useContext(AppContext);

    const onGetResultData = async (resultId) => {
        if ( !resultId ) return;
        const resultRecord = results.find(result => result.record_id === resultId);
        if ( !resultRecord ) return;

        let game_report = resultRecord.data
        if ( ! game_report ) {
            game_report = await getResultData(resultId);
        }
        // console.log(game_report);
        // save report as file
        const blob = new Blob([game_report], {type: "application/json"});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'game_report.json';
        a.click();
        URL.revokeObjectURL(url);
    }

    function onSearch() {
        const searchInput = document.getElementById("searchInput");
        const filter = searchInput.value.toUpperCase();
        const table = document.querySelector("table");
        const body = table.getElementsByTagName("tbody")[0];
        const tr = body.getElementsByTagName("tr");
        for (let i = 0; i < tr.length; i++) {
            const tds = tr[i].getElementsByTagName("td");

            const found = Array.from(tds).some(td => {
                const txtValue = td.textContent || td.innerText;
                return txtValue.toUpperCase().indexOf(filter) > -1;
            })

            if (found) {
                tr[i].style.display = "";
            } else {
                tr[i].style.display = "none";
            }
        }
    }


    const onRefresh = () => {
        setUpdated();
    }
    const debouncedOnRefresh = debounce(onRefresh, 1000);

    if ( identities.length === 0 ) {
        return (
            <h1>Loading...</h1>
        )
    }

    return (
        <>
            <div id="results">
                Results:
                <input type="text" id="searchInput" onKeyUp={onSearch} placeholder="Search...">
                </input>

                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Result ID</th>
                            <th>Game ID</th>
                            <th>Description</th>
                            <th>Reference</th>
                            <th>Score ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((result, index) => {
                            return (
                                <tr key={result.record_id}>
                                    <td>{index}</td>
                                    <td>{result.record_id}</td>
                                    <td>{result.game_record_id}</td>
                                    <td>{JSON.stringify(result.metadata)}</td>
                                    <td>{result.result_reference_scope + " / " + result.result_reference_id}</td>
                                    <td>{result.score_record_id === "00000000-0000-0000-0000-000000000000" ? "skipped" : result.score_record_id}</td>
                                    <td>
                                        <button onClick={() => {onGetResultData(result.record_id)}}>Get Data</button>
                                    </td>
                                </tr>
                            )
                        })}


                    </tbody>
                </table>
            </div>
            <button onClick={() => {debouncedOnRefresh()}}>Refresh</button>
        </>
    );
}

export default Results;