import React, { useContext, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { QueryClient , QueryClientProvider } from "react-query";

import Navbar from "./Navbar";

import Login from "./Login";

import NotFound from "./NotFound";
import Profile from "./Profile";
import Lobbies from "./Lobbies";
import Scores from "./Scores";
import Results from "./Results";
import About from "./About";
import Root from "./Root";
import Logout from "./Logout";

import AuthContextProvider from "./contexts/AuthContextProvider";
import AuthContext from "./contexts/AuthContext";
import AppContextProvider from "./contexts/AppContextProvider";

const ProtectedRoute = ({ component }) => {
  const { authenticated, token } = useContext(AuthContext);
  const Component = component;  // React.createElement(component)
  const navigate = useNavigate();

  useEffect(() => {
    if ( !authenticated ) {
      navigate("/login");
    }
  }, [authenticated, navigate]);

  if ( !authenticated ) {
    return <div>Not authenticated</div>;
  }
  if ( token === null ) {
    return <div>Loading...</div>;
  }
  return <Component />;
};

function App() {
  return (
    <>
      <QueryClientProvider client={new QueryClient()}>
        <AuthContextProvider
        >
          <AppContextProvider>
            <Navbar/>
            <Routes>
                <Route path="/" element={<Root />} />
                <Route path="/profile" element={<ProtectedRoute component={Profile} />} />
                <Route path="/lobbies" element={<ProtectedRoute component={Lobbies} />} />
                <Route path="/scores" element={<ProtectedRoute component={Scores} />} />
                <Route path="/results" element={<ProtectedRoute component={Results} />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/login" element={<Login />} />
                <Route path="/about" element={<About />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
          </AppContextProvider>
        </AuthContextProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;