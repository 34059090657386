import React from 'react';

const AppContext = React.createContext({
    identities: [],
    setIdentities: () => {},
    deleteIdentity: (identityId) => {},
    profiles: [],
    setProfiles: () => {},
    deleteProfile: (profileId) => {},
    createProfile: () => {},
    revisions: [],
    setRevisions: () => {},
    deleteRevision: (profileId, revisionId) => {},
    createRevision: (profileId) => {},
    resources: [],
    setResources: () => {},
    lobbies: [],
    joinLobby: (lobbyId) => {},
    leaveLobby: (lobbyId) => {},
    createLobby: (slot_revision_id, slotCount, slot_key) => {},
    deleteLobby: (lobbyId) => {},
    updateLobby: (lobbyId, options) => {},
    games: [],
    setGames: () => {},
    setUpdated: () => {},
    scores: [],
    getScores: () => {},
    createScore: async (score_scope_type, scope_reference_id) => {},
    results: [],
    getResults: () => {},
    getResultData: () => {},
    updateRevisionClassSource: (profileId, revisionId, classId, classData) => {},
    deleteRevisionClassSource: (profileId, revisionId, classId) => {},
  });

export default AppContext;