import React, { useContext, useEffect, useState } from 'react';

import AuthContext from './contexts/AuthContext';
import Config from './Config';

function Login() {
  const callbackUrl = Config.callbackUrl;
  const clientId = Config.clientId;
  const scope = Config.scope;
  const responseType = Config.responseType;
  const authUrl = Config.authUrl;
  const targetUrl = `${authUrl}?client_id=${clientId}&response_type=${responseType}&scope=${scope}&redirect_uri=${encodeURIComponent(
  callbackUrl
  )}&state=${window.location.pathname}`;

  const [isDisabled, setIsDisabled] = useState(false);
  const { authenticated } = useContext(AuthContext);

  useEffect(() => {
    if (isDisabled) {
      const timer = setTimeout(() => {
        setIsDisabled(false);
      }, 5000); // 5 seconds timer

      return () => clearTimeout(timer);
    }
  }, [isDisabled]);

  if (authenticated) {
      window.history.back();
      return null;
  }

  function onClick() {
    setIsDisabled(true);
    window.location.href = targetUrl
  }

  return (
    <div>
      <button onClick={onClick} disabled={isDisabled}>Login</button>
      {/* <iframe src={targetUrl} title="Login"></iframe> */}
    </div>
  );
}

export default Login;
