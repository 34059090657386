import Config from "./Config";
import axios from "axios";
import { useState, useEffect } from "react";

function createFetcher(token, opts = {}) {
    const cancelTokenSource = axios.CancelToken.source();
    const fetcher = axios.create({
        ...opts,
        cancelToken: cancelTokenSource.token,
        baseURL: Config.api,
        timeout: 5000,
        headers: token ? {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        } : {
            'Content-Type': 'application/json',
        },
    });
    return {fetcher, canceller: cancelTokenSource.cancel}
}

async function deleteIdentity(fetcher, identityId, props = {}) {
    const {identities} = props;
    const {data} = await fetcher.delete(`/api/user/identities/${identityId}`);
    const updatedIdentities = identities ? identities.filter((identity) => identity.record_id !== identityId) : undefined;

    return { ...props, identities: updatedIdentities};
}

async function getIdentities(fetcher, {
    identityId = null,
} = {}) {
    if (identityId) {
        const params = {
            force_create: true,
        };
        const {data} = await fetcher({
            url: `/api/user/identities/${identityId}`,
            method: 'GET',
            params,
        });
        const result = { identities: [data.response] }
        return result;
    }

    const {data} = await fetcher("/api/user/identities");
    const result = { identities: data.response }
    return result;
}

async function getProfiles(fetcher, {
    identities = [],
    profileId = null,
} = {}) {
    var profileIds = [];
    if (profileId) {
        profileIds.push(profileId);
    }

    if (identities.length > 0) {
        profileIds = identities.reduce((acc, identity) => {
            return acc.concat(identity.mapping_profiles);
        }, profileIds);
    }

    var profiles = [];

    if (profileIds.length === 0) {
        const {data} = await fetcher(`/api/user/profiles`);
        profiles = data.response;
    } else {
        const profilePromises = profileIds.map((profileId) => {
            return fetcher(`/api/user/profiles/${profileId}`);
        });
        const profileResponses = await Promise.all(profilePromises);
        const profileData = await Promise.all(profileResponses.map((res) => res.data));

        profiles = profileData.reduce((acc, data) => {
            acc.push(data.response);
            return acc;
        }, []);
    }
    const result = { identities, profiles };
    return result;
}


async function getRevisions(fetcher, {
    identities = [],
    profiles = [],
} = {}) {
    var profileIds = [];
    if (profiles.length > 0) {
        profileIds = profiles.reduce((acc, profile) => {
            return acc.concat(profile.record_id);
        }, profileIds);
    } else {
        profileIds = identities.reduce((acc, identity) => {
            return acc.concat(identity.mapping_profiles);
        }, profileIds);
    }

    const revisionsPromises = profileIds.map((profileId) => {
        return fetcher(`/api/user/profiles/${profileId}/revisions?expand_sources=true&expand_resources=true`);
    });

    const revisionsResponses = await Promise.all(revisionsPromises);
    const revisionsData = await Promise.all(revisionsResponses.map((res) => res.data));

    const revisions = revisionsData.reduce((acc, data) => {
        return acc.concat(data.response);
    }, []);
    const sources = revisionsData.reduce((acc, data) => {
        return acc.concat(data.response_extra.sources);
    }, []);
    const resources = revisionsData.reduce((acc, data) => {
        return { ...acc, ...data.response_extra.resources };
    }, {});

    const result = { identities, profiles, revisions, sources, resources };
    return result;
}

async function deleteProfile(fetcher, profileId, props = {}) {
    const {profiles} = props;
    const {data} = await fetcher.delete(`/api/user/profiles/${profileId}`);
    const updatedProfiles = profiles ? profiles.filter((profile) => profile.record_id !== profileId) : undefined;

    return { ...props, profiles: updatedProfiles, profile: data.response };
}

async function postNewProfile(fetcher, props = {}) {
    const {profiles} = props;
    const {data} = await fetcher.post(`/api/user/profiles`);
    const updatedProfiles = profiles ? profiles.concat(data.response) : undefined;

    return { ...props, profiles: updatedProfiles, profile: data.response };
}

async function deleteRevision(fetcher, profileId, revisionId, props = {}) {
    const {revisions} = props;
    const {data} = await fetcher.delete(`/api/user/profiles/${profileId}/revisions/${revisionId}`);
    const updatedRevisions = revisions ? revisions.filter((revision) => revision.record_id !== revisionId) : undefined;

    return { ...props, revisions: updatedRevisions, revision: data.response };
}

async function postNewRevision(fetcher, profileId, props = {}) {
    const {revisions} = props;
    const {data} = await fetcher.post(`/api/user/profiles/${profileId}/revisions`);
    const updatedRevisions = revisions ? revisions.concat(data.response) : undefined;

    return { ...props, revisions: updatedRevisions, revision: data.response };
}

async function postNewRevisionClassSource(fetcher, profileId, revisionId, classId, classData, props = {}) {
    const {sources} = props;
    const form = new FormData();
    const blobData = new Blob([classData], { type: 'application/text' });
    form.append(`file`, blobData, `${classId}`);

    const {data} = await fetcher.post(
        `/api/user/profiles/${profileId}/revisions/${revisionId}/class/${classId}/source`,
        form,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    const updatedSources = sources ? sources.map((source) => {
        if (source.record_id === classId) {
            return data.response;
        }
        return source;
    }) : undefined;
    return { ...props, sources: updatedSources, source: data.response };
}

async function deleteRevisionClassSource(fetcher, profileId, revisionId, classId, props = {}) {
    const {data} = await fetcher.delete(
        `/api/user/profiles/${profileId}/revisions/${revisionId}/class/${classId}/source`);
    return { sources: data.response, ...props };
}

async function getLobbies(fetcher, props = {}) {
    const {data} = await fetcher.get('/api/user/lobbies');
    return { lobbies: data.response, ...props };
}

async function postJoinLobby(fetcher, lobbyId, slot_key = null, slot_revision_id = null, props = {
    lobbies: [],
}) {
    var params = {};
    if (slot_key) {
        params.slot_key = slot_key;
    }
    if (slot_revision_id) {
        params.slot_revision_id = slot_revision_id;
    }

    const body = JSON.stringify(params);

    const {data} = await fetcher.post(`/api/user/lobbies/${lobbyId}/join`, body);
    const thisLobby = data.response;
    const lobbies = props.lobbies.map((lobby) => {
        if (lobby.record_id === lobbyId) {
            return thisLobby;
        }
        return lobby;
    });

    return { lobbies: lobbies, ...props };
}

async function postLeaveLobby(fetcher, lobbyId, slot_key = null) {
    var params = {};
    if (slot_key) {
        params.slot_key = slot_key;
    }

    const body = JSON.stringify(params);
    const {data} = await fetcher.post(`/api/user/lobbies/${lobbyId}/leave`, body);
    return data.response;
}

async function postCreateLobby(fetcher, slots = {}) {
    // slots lobby_configuration public
    const body = JSON.stringify({ slots });
    const {data} = await fetcher.post(`/api/user/lobbies`, body);
    return data.response;
}

async function postUpdateLobby(fetcher, lobbyId, options = {}) {
    const { ready, add_slots_keys, update_slots, remove_slots_keys, owner_profile_id, remove_owner_profile_id, lobby_configuration, is_public } = options;
    const body = JSON.stringify({
        ready,
        add_slots_keys,
        update_slots,
        remove_slots_keys,
        owner_profile_id,
        remove_owner_profile_id,
        lobby_configuration,
        is_public
    });
    const {data} = await fetcher.post(`/api/user/lobbies/${lobbyId}/update`, body);
    return data.response;
}

async function deleteLobby(fetcher, lobbyId) {
    const {data} = await fetcher.delete(`/api/user/lobbies/${lobbyId}`);
    return data.response;
}


function getStorageValue(key, defaultValue) {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

async function getScores(fetcher, props = {}) {
    // filter_reference_ids, filter_reference_scopes, exclusive_start_key, limit
    const { filter_reference_ids, filter_reference_scopes, exclusive_start_key, limit } = props;
    const params = {};
    if (filter_reference_ids) {
        params.filter_reference_ids = filter_reference_ids;
    }
    if (filter_reference_scopes) {
        params.filter_reference_scopes = filter_reference_scopes;
    }
    if (exclusive_start_key) {
        params.exclusive_start_key = exclusive_start_key;
    }
    if (limit) {
        params.limit = 100;
    }

    const {data} = await fetcher.get('/api/user/scores', { params });
    return { scores: data.response };
}

async function getResults(fetcher, props = {}) {
    const { filter_reference_ids, filter_reference_scopes, filter_only_scored, filter_not_scored, expand_result_data, exclusive_start_key, limit } = props;
    const params = {};
    if (filter_reference_ids) {
        params.filter_reference_ids = filter_reference_ids;
    }
    if (filter_reference_scopes) {
        params.filter_reference_scopes = filter_reference_scopes;
    }
    if (filter_only_scored) {
        params.filter_only_scored = filter_only_scored;
    }
    if (filter_not_scored) {
        params.filter_not_scored = filter_not_scored;
    }
    if (expand_result_data) {
        params.expand_result_data = expand_result_data;
    }
    if (exclusive_start_key) {
        params.exclusive_start_key = exclusive_start_key;
    }
    if (limit) {
        params.limit = 100;
    }

    const {data} = await fetcher.get('/api/user/results', { params });
    return { results: data.response };
}

async function getResultData(fetcher, resultId, opts = {}) {
    const { results } = opts;
    const params = {
        expand_result_data: true,
    };

    const {data} = await fetcher.get('/api/user/results/' + resultId, { params });
    const updatedResults = results.map((result, index) => result.record_id === resultId ? data.response : result);
    const game_report = data.response.data;
    return { results: updatedResults, game_report: game_report };
}

async function postScore(fetcher, score_reference_scope, score_reference_id, opts = {}) {
    const { scores } = opts;
    const body = JSON.stringify({
        score_reference_scope,
        score_reference_id,
    });
    const {data} = await fetcher.post('/api/user/scores', body);
    const updatedScores = scores ? scores.concat(data.response) : undefined;
    return { scores: updatedScores, score: data.response };
}

function useLocalStorage (key, defaultValue) {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

const debounce = (callback, wait) => {
    // Dummy debounce function
    return (...args) => {
        callback(...args);
    };
  }
export {
    createFetcher,
    deleteIdentity,
    getIdentities,
    getRevisions,
    getProfiles,
    useLocalStorage,
    // useDebounce,
    debounce,
    getLobbies,
    postJoinLobby,
    postLeaveLobby,
    postCreateLobby,
    postUpdateLobby,
    deleteLobby,
    getScores,
    getResults,
    postScore,
    getResultData,
    postNewRevisionClassSource,
    deleteRevisionClassSource,
    deleteRevision,
    postNewRevision,
    postNewProfile,
    deleteProfile,
};