// import config from 'config.json';

const config = await fetch('/assets/config.json').then((res) => res.json());
console.log("config: ", config);

const Config = {
    "application" : {
        "UnitHexClassUUID": "162045f0-16a2-4929-b8c7-a47ad5a05389",
        "UnitSquareClassUUID": "d2d48c83-e205-474e-883c-987d4feaa2ee",
    },
    callbackUrl: config.callbackUrl ? config.callbackUrl : `${window.location.origin}`,
    authUrl: config.authUrl ? config.authUrl : `${window.location.origin}/mock-login`,
    signoutUrl: config.signoutUrl ? config.signoutUrl : `${window.location.origin}/mock-logout`,
    ...config,
}

export default Config;