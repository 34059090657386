// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    display: flex;
    justify-content: space-between;
    align-items: left;
    background-color:#f1f1c1;
    color: #fff;
    padding: 1rem;
  }`, "",{"version":3,"sources":["webpack://./src/Navbar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,iBAAiB;IACjB,wBAAwB;IACxB,WAAW;IACX,aAAa;EACf","sourcesContent":[".navbar {\n    display: flex;\n    justify-content: space-between;\n    align-items: left;\n    background-color:#f1f1c1;\n    color: #fff;\n    padding: 1rem;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
