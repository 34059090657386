import React, { useState, useEffect, useContext } from "react";

import "./Scores.css";
import Config from "./Config";
import AppContext from "./contexts/AppContext";
import { debounce } from "./utils";

function Scores() {

    const {
        identities,
        profiles,
        revisions,
        lobbies,
        scores,
        setUpdated,
        createScore,
    } = useContext(AppContext);

    function onSearch() {
        const searchInput = document.getElementById("searchInput");
        const filter = searchInput.value.toUpperCase();
        const table = document.querySelector("table");
        const body = table.getElementsByTagName("tbody")[0];
        const tr = body.getElementsByTagName("tr");
        for (let i = 0; i < tr.length; i++) {
            const tds = tr[i].getElementsByTagName("td");

            const found = Array.from(tds).some(td => {
                const txtValue = td.textContent || td.innerText;
                return txtValue.toUpperCase().indexOf(filter) > -1;
            })

            if (found) {
                tr[i].style.display = "";
            } else {
                tr[i].style.display = "none";
            }
        }
    }

    const onCreateScore = async (score_scope_type, scope_reference_id) => {
        await createScore(score_scope_type, scope_reference_id);
    }

    const onRefresh = () => {
        setUpdated();
    }
    const debouncedOnRefresh = debounce(onRefresh, 1000);

    if ( identities.length === 0 ) {
        return (
            <h1>Loading...</h1>
        )
    }

    return (
        <>
            <div id="scores">
                Scores:
                <input type="text" id="searchInput" onKeyUp={onSearch} placeholder="Search...">
                </input>

                <table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Score ID</th>
                            <th>Scope</th>
                            <th>Reference</th>
                            <th>Rev.</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {scores.map((score, index) => {
                            return (
                                <tr key={score.record_id}>
                                    <td>{index}</td>
                                    <td>{score.record_id}</td>
                                    <td>{score.score_scope_type}</td>
                                    <td>{score.scope_reference_id}</td>
                                    <td>{score.revision}</td>
                                    <td>{score.score}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <button onClick={() => {debouncedOnRefresh()}}>Refresh</button>
            <div id="score-create">
                <h2>Create Score</h2>
                <form onSubmit={(e) => {
                    e.preventDefault();
                    const score_scope_type = document.getElementById("score_scope_type").value;
                    const scope_reference_id = document.getElementById("scope_reference_id").value;
                    onCreateScore(score_scope_type, scope_reference_id);
                }
                }>
                    <label>
                        Scope:
                        <select id="score_scope_type" onChange={(e) => {
                            const scope = e.target.value;
                            const referenceIds = document.getElementById("scope_reference_id");
                            if (scope === "identity") {
                                referenceIds.innerHTML = "";
                                identities.forEach((identity) => {
                                    const option = document.createElement("option");
                                    option.value = identity.record_id;
                                    option.text = identity.record_id;
                                    referenceIds.add(option);
                                });
                            } else if (scope === "profile") {
                                referenceIds.innerHTML = "";
                                profiles.forEach((profile) => {
                                    const option = document.createElement("option");
                                    option.value = profile.record_id;
                                    option.text = profile.record_id;
                                    referenceIds.add(option);
                                });
                            } else if (scope === "revision") {
                                referenceIds.innerHTML = "";
                                revisions.forEach((revision) => {
                                    const option = document.createElement("option");
                                    option.value = revision.record_id;
                                    option.text = revision.record_id;
                                    referenceIds.add(option);
                                });
                            }}}
                            required>
                            <option value="identity">Identity</option>
                            <option value="profile">Profile</option>
                            <option value="revision">Revision</option>
                        </select>
                    </label>
                    <label>
                        Reference ID:
                        <select id="scope_reference_id"
                        placeholder="Reference ID"
                        required>
                        </select>
                    </label>
                    <button type="submit">Create Score</button>
                </form>
            </div>
            <br />
            <div>
                <p>
                    <small>
                        <i>
                            <b>Note:</b> Scores profiles must be created before score engine can be used.
                            <br />
                            <b>Note:</b> Score records are permanent and cannot be deleted.
                        </i>
                    </small>
                </p>
            </div>
        </>
    );
}

export default Scores;