import React from "react";

function About() {
    return (
        <>
            <h1>Codegames</h1>
            <a href="https://github.com/outwarped/codegames" target="_blank" rel="noreferrer">GitHub repository</a>
        </>
        );
}

export default About;