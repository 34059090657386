// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#results table thead th, td:not(:last-child) {
    border: 1px solid;
    padding: 8px;
    /* background-color: #f1f1c1; */
    border-collapse: collapse;
}

#scores table tbody tr:hover {
    background-color: #f1f1c1;
}`, "",{"version":3,"sources":["webpack://./src/Results.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,YAAY;IACZ,+BAA+B;IAC/B,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["#results table thead th, td:not(:last-child) {\n    border: 1px solid;\n    padding: 8px;\n    /* background-color: #f1f1c1; */\n    border-collapse: collapse;\n}\n\n#scores table tbody tr:hover {\n    background-color: #f1f1c1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
